/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import withStyles from '@mui/styles/withStyles';
import HeroReusableV2 from "./HeroReusableV2";
// import ModalErrorHandler from "../ModalErrorHandler";
import { Button } from "@mui/material";

const Hero8 = () => {
  const ColorButton = withStyles(() => ({
    root: {
      width: "100%",
      height: "35px",
    },
  }))(Button);
  // Router
  const versionNumber = "002";
  // Input fields State
  return (
    <HeroReusableV2
      headerWithDesc={false}
      versionNumber={versionNumber}
      ColorButton={ColorButton}
      Animation={"HeroAnimation"}
    />
  );
};
export default Hero8;
